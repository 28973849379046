const dic = {
    "Chapter": "Розділ", 
    "Psalm": "Псалом", 
    "Ukrainian": "Українська",
    "Russian": "Російська",
    "English": "Англійська",
    "Compare": "Порівняння",
    "Gallery": "Галерея",
    "History of reading": "Історія читання",
    "Bible lessons": "Біблійні уроки",
    "Bible courses": "Біблійні курси",
    "Courses": "Курси",
    "Registration": "Реєстрація",
    "Exit": "Вихід",
    "Sign in": "Вхід",
    "Settings": "Налаштування",
    "Search": "Пошук",
    "Parallel places": "Паралельнi мiсця",
    "Numbering of verses": "Нумерацiя вiршiв",
    "Footnotes": "Виноски",
    "Translation": "Переклад",
    "Books": "Книги",
    "Select a section": "Вибрати розділ",
    "Search with register": "Пошук з враховуванням регістру",
    "Search whole word": "Пошук цілого слова ( як введено)",
    "New testament": "Новий завіт",
    "Old testament": "Старий завіт",
    "The whole Bible": "Вся Біблія",
    "Home": "Головна",
    "Enter the text": "Введіть текст",
    "To find": "Знайти",
    "Search taking into account the register": "Пошук з урахуванням регістру",
    "Search for a whole word (as entered)": "Пошук цілого слова (як введено)",
    "In Ukrainian": "Українською",
    "In Russian": "Російською",
    "In English": "Англійською",
    "Greetings": "Вітання",
    "Birthday": "З днем Народження",
    "Inspirations": "Натхнення",
    "Wedding": "З днем Весілля",
    "Quotes": "Цитати",
    "Psalms": "Псалми",
    "Saturday": "З днем Суботнім",
    "Small": "Дрібний",
    "Medium": "Середній",
    "Large": "Великий",
    "Select": "Вибрати",
    "Copy": "Копіювати",
    "Share": "Поширити",
    "Share viber": "Поділитись в Viber",
    "Share telegram": "Поділитись в Telegram",
    "Share whatsapp": "Поділитись в WhatsApp",
    "Selected": "Вибрано",
    "Strong's Dictionary": "Словник Стронга",
    "Pronunciation": "Вимова",
    "Transliteration": "Транслітерація",
    "Empty list": "Порожній список",
    "End of the book": "Кінець книги",
    "Plan": "План",
    "Morning reading": "Ранкове читання",
    "Bible": "Біблія",
    "Read": "Читати",
    "Bible reading plan": "План читання Біблії",
    "Select translation": "Виберіть переклад",
    "Reading for today": "Читання на сьогодні",
    "More details": "Детальніше",
    "Select book": "Виберіть книгу",
    "More pictures": "Більше картинок",
    "Copied": "Скопійовано",
    "Bible reading plans": "Плани читання Біблії",
    "Start a plan": "Розпочати план",
    "Read the plan": "Читати цей план",
    "Complete the plan": "Завершити план",
    "Day": "День",
    "Do you really want to delete your reading progress": "Ви дійсно бажаєте видалити прогресс читання",
    "Yes": "Так",
    "Bible School": "Школа Біблії",
    "No": "Ні",
    "Recited": "Прочитано",
    "Gospels and Epistles": "Євангелія та послання",
    "The Pentateuch and the history of Israel": "П’ятикнижжя та історія Ізраїлю",
    "Psalms and wisdom literature": "Псалми та література мудрості",
    "Chronicles and prophets": "Хроніки і пророки",
    "Chronological Bible": "Хронологічна Біблія",
    "Happy Birthday": "З Днем народження",
    "Happy Baptism Day": "З Днем хрещення",
    "With the birth of a daughter": "З народженням доньки",
    "With the birth of a son": "З народженням сина",
    "Happy New Year": "З Новим Роком",
    "To the best grandmother": "Вітання бабусі",
    "The best mom": "Вітання мами",
    "The best father": "Вітання батька",
    "The best grandfather": "Вітання дідуся",
    "Biblical": "Біблійні",
    "Greetings tab": "Привітальні",
    "Paul\'s message": "Послання Павла",
    "Jacob, Peter, Ivan": "Якова, Петра, Івана",
    "Gospels": "Євангелії",
    "Pentateuch": "П'ятикнижжя ",
    "Proverbs, Ecclesiastes": "Притчі, Екклезіаста",
    "Great prophets": "Великі пророки",
    "Isaiah": "Ісая",
    "Jeremiah": "Єремія ",
    "Ezekiel": "Єзекіїля",
    "Minor prophets": "Малі пророки ",
    "Daniel": "Даниїла ",
    "Revelation": "Об'явлення",
    "Acts of the Apostles": "Дії апостолів ",
    "Thematic": "Тематичні",
    "Good morning": "Доброго ранку",
    "Good evening": "Доброго вечора",
    "Good night": "Добраніч"
    
    

    
}

export default dic;